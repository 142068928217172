.PhoneInput {
  display: flex;
  flex-direction: column;
  input {
    width: 100% !important;
    &::placeholder {
      color: #222 !important;
      opacity: 0.6 !important;
    }
  }
}
