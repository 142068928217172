.FAQ {
  margin-top: 35px;
  margin-bottom: 55px;

  h1 {
    display: none;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    .Question {
      flex-basis: 48%;
    }
  }
}

// @media (max-width: 1024px) {
//   .FAQ {
//     display: block;
//     padding-top: 30px;
//     padding-left: 20px;
//     padding-right: 20px;
//     padding-bottom: 30px;
//     h1 {
//       display: block;
//       margin: 0;
//       margin-bottom: 30px;
//       font-weight: 600;
//       font-size: 30px;
//       line-height: 36px;
//       color: white;
//     }
//     .container {
//       max-width: unset;
//     }
//   }
// }
