.BuyOrRentPage {
  min-height: calc(100vh - 182px);
  .filtersContainerx {
    padding-top: 160px;
    padding-bottom: 30px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
      url(../../assets/images/Rent.jpg);
    background-size: cover;
    h1 {
      margin: 0;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 100px;
      line-height: 120px;
      color: white;
      span {
        color: #ef9725;
      }
    }
  }
  .filtersContainer {
    padding-top: 160px;
    padding-bottom: 30px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
      url(../../assets/images/Buy.jpg);
    background-size: cover;

    h1 {
      margin: 0;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 100px;
      line-height: 120px;
      color: white;
      span {
        color: #ef9725;
      }
    }
  }

  .mainContent {
    padding-top: 64px;
    background-color: #2b2724;
    padding-bottom: 50px;
    .propertyListContainer {
      display: flex;
      justify-content: center;
      padding-left: 100px;
      padding-right: 100px;

      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .BuyOrRentPage {
    min-height: unset;
    .filtersContainerx {
      height: unset;
      padding-top: 77px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      background-position: center;
      h1 {
        font-size: 60px;
      }
    }

    .filtersContainer {
      height: unset;
      padding-top: 77px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      background-position: center;
      h1 {
        font-size: 60px;
      }
    }

    .mainContent {
      padding-top: 30px;
      padding-bottom: 30px;
      .propertyListContainer {
        display: block;
        padding-left: 20px;
        padding-right: 0;

        &:first-child {
          margin-bottom: 30px;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .BuyOrRentPage {
    min-height: unset;
    .filtersContainerx {
      height: unset;
      padding-top: 77px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      background-position: center;
      h1 {
        font-size: 40px;
        line-height: normal;
      }
    }
    .filtersContainer {
      height: unset;
      padding-top: 77px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      background-position: center;
      h1 {
        font-size: 40px;
        line-height: normal;
      }
    }
  }
}
