.TextInput {
  textarea {
    font-size: 14px;
    height: 130px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    width: 100%;
    font-weight: 500;
    line-height: 20px;
    padding: 15px 20px;
    color: #222;
    opacity: 1;
    border-radius: 0px 90px 90px 90px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background: linear-gradient(
      109deg,
      rgba(201, 201, 201, 0.8) 1.57%,
      rgba(196, 196, 196, 0.1) 100%
    );
    backdrop-filter: blur(30px);
    &:focus {
      border: none;
      outline: none;
    }
    &::placeholder {
      color: #222;
      opacity: 0.6;
    }
  }
  input {
    font-size: 14px;
    border: none;
    width: 100%;
    font-weight: 500;
    padding: 15px 20px;
    border-radius: 90px;
    background-color: #e6e6e6;
    color: #222;
    opacity: 1;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #222;
      opacity: 0.6;
    }
  }
  .titleInputStyle {
  }
  .descriptionInputStyle {
    min-height: 157px;
  }
}

@media (max-width: 1024px) {
  .TextInput {
    .label {
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    input {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media (max-width: 1024px) {
  .TextInput {
    .label {
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    input {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
