.PropertyListMobile {
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    span {
      color: #ef9725;
      font-family: 'Oxygen', sans-serif;
      margin-left: 10px;
    }
    margin: 0;
    color: white;
    margin-bottom: 30px !important;
    font-weight: 300;
    font-size: 30px;
    font-family: 'Oxygen', sans-serif;
    align-items: center;
    display: flex;
  }

  .listContainer {
    overflow-x: auto;
    scrollbar-width: none;
    margin-bottom: 30px;
    max-width: 100vw;

    &::-webkit-scrollbar {
      display: none;
    }

    .list {
      display: flex;

      li {
        padding: 20px;
      }
    }

    .inner {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

      &::after {
        content: '';
        display: block;
        height: 20px;
        min-width: 15px;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 4px;

    .page {
      width: 7px;
      height: 7px;
      background-color: #d9d9d9;
      border-radius: 50%;
      transition: all 0.2s;

      &.active {
        width: 26px;
        background-color: #ef5225;
        border-radius: 30px;
      }
    }
  }

  .showMoreContainer {
    text-align: center;
    margin: 3rem 0;
  }

  .showMoreButton {
    background: #f39a1f;
    color: white;
    border: none;
    padding: 1rem 3rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background 0.3s ease;

    &:hover {
      background: darken(#f39a1f, 10%);
    }

    &:disabled {
      background: #ccc;
      cursor: not-allowed;
    }
  }
}

@media (max-width: 768px) {
  .PropertyListMobile {
    h2 {
      margin-bottom: 50px;
      font-size: 30px !important;
      line-height: 32px;
      align-items: center;
      display: flex;
    }
    .listContainer {
      width: fit-content;

      .inner {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }

  .showMoreButton {
    background: #f39a1f;
    color: white;
    border: none;
    padding: 1rem 3rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background 0.3s ease;

    &:hover {
      background: darken(#f39a1f, 10%);
    }

    &:disabled {
      background: #ccc;
      cursor: not-allowed;
    }
  }
}
