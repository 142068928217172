.Property {
  width: 70vw;
  max-width: 240px;
  border-radius: 10px;
  color: white;
  background-color: #343434;
  position: relative;
  &:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }

  .price {
    display: flex;
    align-items: center;
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ef9725;
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    font-size: 15px;
    width: max-content;
    border-radius: 8px;
    padding: 2px 20px;
    z-index: 2;
  }

  header {
    position: relative;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0;
    }

    button {
      cursor: pointer;
      text-align: start;
      background-color: transparent;
      display: flex;
      font-weight: bold;
      align-items: center;
      border: none;
      position: absolute;
      z-index: 2;
      height: 50px;
      font-size: 18px;
      color: white;
      font-family: 'Oxygen', sans-serif;
      p {
        display: flex;
        align-items: center;
        color: #ef9725;
        margin-left: 7px;
        margin: 0;
      }
      svg {
        transform: rotate(180deg);
        font-size: 35px;
      }
    }
  }

  .propertyTitle {
    margin: 0;
    font-weight: 100;
    padding: 8px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 22px;
    overflow: hidden;
  }

  .description {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .propertyContainer {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .propertyContainerIcon {
    object-fit: contain;
  }

  .propertyContainerText {
    font-size: 0.6rem;
    white-space: nowrap;
  }

  .detailsButton {
    height: 50px;
    border: none;
    display: flex;
    justify-content: space-evenly;
    padding: 5px;
    flex-shrink: 1;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    border-top: 0.5px solid rgb(129, 129, 129);
  }

  .buttonContainer > .detailsButton:nth-child(2) {
    border-right: 0.5px solid rgb(129, 129, 129);
    border-left: 0.5px solid rgb(129, 129, 129);
    padding-right: 15px;
  }
}
