.Property {
  cursor: pointer;
  color: white;
  border-radius: 20px;
  background-color: #343434;
  position: relative;
  min-height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
  .price {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ef9725;
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    font-size: 20px;
    width: max-content;
    border-radius: 12px;
    padding: 5px 25px;
    z-index: 2;
  }

  header {
    position: relative;
    width: 100%;
    height: 278px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 0 0;
      transition: all 0.5s ease-in-out;
      object-fit: cover;
    }
  }

  h1 {
    margin: 0px;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    padding: 5px 17px;
    line-height: 32px;
    overflow: hidden;
  }

  .propertyContainer {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .propertyContainerIcon {
    object-fit: contain;
  }

  .propertyContainerText {
    font-size: 0.9rem;
    white-space: nowrap;
  }

  .detailsButton {
    height: 50px;
    border: none;
    display: flex;
    justify-content: space-evenly;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    // position: absolute;
    // bottom: 10px;
    border-top: 0.5px solid rgb(129, 129, 129);

    justify-content: space-around;
    align-items: center;
  }

  .buttonContainer > .detailsButton:nth-child(2) {
    border-right: 0.5px solid rgb(129, 129, 129);
    border-left: 0.5px solid rgb(129, 129, 129);
    padding: 8px;
  }
}
