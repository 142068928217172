@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
.container {
  max-width: 1320px;
  margin: 0 auto;
}
@media (max-width: 1350px) {
  .container {
    padding: 0 20px;
  }
}
.swiper-pagination {
  position: unset !important;
  margin-top: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  opacity: 0.2 !important;
  background-color: white;
}
.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  opacity: 1 !important;
  border-radius: 10px;
}
@media (max-width: 1023px) {
  .swiper-pagination {
    margin-top: 15px;
  }
}
.ds {
  background-image: url('../src/assets/images/webinar-bg.png');
  background-size: cover;
}
