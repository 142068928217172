.AddPropertyPage {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 182px);
  padding-top: 120px;
  padding-left: 100px;
  padding-right: 100px;

  .container {
    flex: 1;
    max-width: 1240px;
    input {
      border: 1px solid #ccc;
    }
    textarea {
      border: 1px solid #ccc;
    }
    form {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      label {
        display: flex;
        align-items: center;
        input,
        textarea,
        select {
          margin-left: 10px;
        }
      }
    }

    button {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}
