.Header {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  width: 100%;

  height: 80px;
  margin: auto;
  .link {
    display: flex;
    z-index: 1000;
    align-items: center;
  }
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    height: 80px;
    background-color: #2b2724;
    z-index: -2;
  }
  &.russian {
    .container {
      .navigationMenu {
        max-width: 1000px;
      }
    }
  }
  .languageSwitcher {
    display: flex;
    gap: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 14px;
    z-index: 1000;
    align-items: center;
    font-weight: 800;
    border-radius: 50%;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    span {
      position: relative;
      width: 46px;
      height: 46px;
      margin-bottom: 6px;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
    img {
      width: 30px;
    }
    .languagesList {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% + 20px);
      padding-top: 5px;
      transition: all 0.3s ease-in-out;
      padding-bottom: 5px;
      background-color: #262626;
      border-radius: 8px;
      position: absolute;
      bottom: -164px;
      padding: 0 20px;
      left: 50%;
      transform: translateX(-50%);
      div:first-child {
        padding-top: 20px;
      }

      div:last-child {
        padding-bottom: 20px;
      }
      .secondLang {
        padding: 20px 0;
      }
      div {
        display: flex;
        gap: 5px;
      }
    }
  }
  .menuButton {
    display: none;
  }

  .container {
    display: flex;
    flex: 1;
    max-width: 1240px;
    align-items: center;
    justify-content: center;
    .phone {
      margin-left: auto;
      padding-left: 20px;
      font-family: 'Smooch Sans';
      text-decoration: none;
      font-weight: 600;
      font-size: 22px;
      line-height: 148%;
      color: white;
    }
    .navigationMenu {
      flex: 1;
      max-width: 835px;
      margin-left: 40px;
      ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          a {
            text-decoration: none;
            padding-bottom: 4px;
            font-weight: 800;
            font-size: 16px;
            line-height: 148%;
            color: white;
            opacity: 0.65;
            &.link {
              opacity: 1;
            }
            &:hover {
              opacity: 1;
            }
            &.active {
              position: relative;
              opacity: 1;
              &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 30px;
                height: 2px;
                background-color: #ef9725;
                border-radius: 6px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1350px) {
  .Header {
    .languageSwitcher {
      margin: 0 0 0 20px;
    }
  }
}

@media (max-width: 1024px) {
  .Header {
    height: 47px;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    &::before {
      height: 47px;
    }
    .languageSwitcher {
      margin: 0;
      top: 10px;
      right: 64px;
      font-weight: 600;
      font-size: 18px;
      .languagesList {
        bottom: -102px;
        border-radius: 4px;
        div:first-child {
          padding-top: 10px;
        }

        div:last-child {
          padding-bottom: 10px;
        }
        .secondLang {
          padding: 10px 0;
        }
      }
      span {
        width: 32px;
        height: 32px;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .menuButton {
      display: block;
      z-index: 1000;
      margin-left: 15px;
      display: flex;
      align-items: center;
      button {
        background-color: transparent;
        padding: 0;
        height: 100%;
        border: none;
        height: 15px;
      }
    }
    .logo {
      top: 2px;
      width: 65px;
    }
    .container {
      display: flex;
      align-items: center;
      .phone {
        margin-right: 80px;
        padding-left: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 148%;
        white-space: nowrap;
      }
      .navigationMenu {
        display: none;
      }
    }
  }
}
