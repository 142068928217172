.PropertyList {
  width: 100%;
  padding: 20px;
  flex: 1;
  max-width: 1240px;
  margin-bottom: 80px;
  position: relative;
  h2 {
    color: white;
    span {
      color: #ef9725;
      font-family: 'Oxygen', sans-serif;
      margin-left: 10px;
    }
    margin: 0;
    margin-bottom: 50px;
    font-weight: 300;
    font-size: 50px;
    line-height: 32px;
    font-family: 'Oxygen', sans-serif;
    align-items: center;
    display: flex;
    &::before {
      content: '';
      height: 2.5px;
      width: 150px;
      margin-right: 20px;
      background-color: #f39a1f;
    }
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.gridItem {
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
}

.showMoreContainer {
  text-align: center;
  margin: 3rem 0;
}

.showMoreButton {
  background: #f39a1f;
  color: white;
  border: none;
  padding: 1rem 3rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background 0.3s ease;

  &:hover {
    background: darken(#f39a1f, 10%);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}

// Mobile responsive
@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}
