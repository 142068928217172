.SearchResults {
  padding-bottom: 96px;
}

@media (max-width: 1024px) {
  .SearchResults {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 30px;
  }
}
