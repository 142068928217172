.RenderTeam {
  position: relative;
  cursor: pointer;

  .photoContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 1;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contactInfo {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all 0.4s ease-out;

    &.opaque {
      opacity: 1;
    }

    h1 {
      margin-bottom: 0;
      color: white;
      font-weight: 600;
      font-size: 26px;
      text-align: center;
    }

    p {
      text-align: center;
      color: white;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

@media (max-width: 1024px) {
  .RenderTeam {
    cursor: auto;
    .photoContainer {
      height: unset;
      aspect-ratio: 185 / 139;
      margin-bottom: 10px;
      border-radius: 6px;
      overflow: hidden;
    }
    .contactInfo {
      bottom: 10px;
      border-radius: 6px;
      h1 {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        white-space: nowrap;
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
      }
    }
  }
}
