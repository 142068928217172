.Sidebar {
  position: fixed;
  z-index: 2;
  opacity: 0;
  top: 0px;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  height: fit-content;
  bottom: 0;
  width: 100%;
  background-color: #2b2724;
  &.show {
    opacity: 1;
    visibility: visible;
  }

  .navigationMenu {
    padding-top: 48px;
    ul {
      border-top: 2px solid rgba(30, 30, 30, 1);
      list-style: none;
      margin: 0;
      padding: 30px 0;
      display: flex;
      background-image: url('../../../../assets/images/mobile-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      li {
        a {
          padding-bottom: 4px;
          text-decoration: none;
          font-weight: 700;
          font-size: 20px;
          line-height: 148%;
          color: white;
          opacity: 0.65;

          &.link {
            opacity: 1;
          }

          &:hover {
            opacity: 1;
          }

          &.active {
            position: relative;
            opacity: 1;

            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 30px;
              height: 2px;
              background-color: #ef9725;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}
