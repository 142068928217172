.Button {
  margin-top: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 800;
  line-height: 14px;
  width: 100%;
  border-radius: 45px;
  background-color: #f26e21 !important;
  border: none;
  cursor: pointer;
  opacity: 1;

  &.disabled {
    cursor: auto;
    background-color: #f26e21 !important;
    opacity: 0.7;
  }
}
