.container {
  max-width: 1240px;
  padding-top: 175px;
  margin: auto;
  .propertyHolder {
    display: flex;
    justify-content: space-between;
    .left {
      width: 46%;
      h1 {
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        margin: 0;
        color: #f39a1f;
        text-transform: capitalize;
        margin-bottom: 35px;
        font-size:42px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          height: 2.5px;
          width: 130px;
          margin-right: 20px;
          background-color: #f39a1f;
          display: inline-block;
        }
      }
      p {
        margin: 0;
        font-size: 21px;
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        text-indent: 20px;
      }
    }
    .right {
      margin-top: 18px;
      width: 50%;
    }
  }
  .topic {
    margin-top: 50px;
    h2 {
      font-family: 'Oxygen', sans-serif;
      font-weight: 400;
      margin: 0;
      color: black;
      margin-bottom: 22px;
      font-size: 40px;
    }
    p {
      margin: 0;
      font-size: 22px;
      font-family: 'Oxygen', sans-serif;
      font-weight: 400;
      white-space: pre-line;
    }
  }
  .last {
    margin-top: 40px;
    margin-bottom: 70px;
  }
  h2 {
    margin: 0;
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
    margin: 0;
    color: black;
    margin-bottom: 22px;
    font-size: 22px;
  
  }
}
@media(max-width:1350px) {
  .container {
    padding: 175px 20px 0;
  }
}
@media(max-width:1024px) {
  .container {
    max-width: 1240px;
    padding-top: 80px;
    margin: auto;
    .propertyHolder {
      display: flex;
      justify-content: space-between;
      .left {
        width: 46%;
        h1 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          margin: 0;
          color: #f39a1f;
          text-transform: capitalize;
          margin-bottom: 20px;
          font-size: 30px;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 120px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0;
          font-size: 18px;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          text-indent: 20px;
        }
      }
      .right {
        margin-top: 18px;
        width: 50%;
      }
    }
    .topic {
      margin-top: 35px;
      h2 {
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        margin: 0;
        color: black;
        margin-bottom: 22px;
        font-size: 30px !important;
      }
      p {
        margin: 0;
        font-size: 22px;
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        white-space: pre-line;
      }
    }
    .last {
      margin-top: 40px;
      margin-bottom: 70px;
      h2 {
        margin: 0;
        font-family: 'Oxygen', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        margin-bottom: 22px;
        font-size: 17px !important;
      }
    }
    
  }
}
@media(max-width:750px) {
  .container {
    max-width: 1240px;
    padding-top: 50px;
    margin: auto;
    .propertyHolder {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      .left {
        width: 100%;
        h1 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          margin: 0;
          color: #f39a1f;
          text-transform: capitalize;
          margin-bottom: 10px;
          font-size: 30px;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 100px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0;
          font-size: 18px;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          text-indent: 20px;
        }
      }
      .right {
        margin-top: 18px;
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .topic {
      margin-top: 35px;
      h2 {
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        margin: 0;
        color: black;
        margin-bottom: 22px;
        font-size: 25px !important;
      }
      p {
        margin: 0;
        font-size: 22px;
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        white-space: pre-line;
      }
    }
    .last {
      margin-top: 40px;
      margin-bottom: 70px;
      h2 {
        margin: 0;
        font-family: 'Oxygen', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        margin-bottom: 22px;
        font-size: 17px !important;
      }
    }
    
  }
}