a.active {
  color: white !important;
  opacity: 1;
  &::after {
    content: '';
    top: 15px;
    right: 5px;
    transform: rotate(90deg);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #ef9725;
    border-radius: 6px;
  }
}

.about {
  padding-top: 60px;
  max-width: 1240px;
  margin-bottom: 68px;
  margin-left: auto;
  margin-right: auto;
  ul {
    li {
      p {
        font-family: 'Oxygen', sans-serif;
        font-weight: 300;
        white-space: pre-line;
      }
    }
  }
  h2 {
    font-family: 'Oxygen', sans-serif;
    font-weight: 300;
    margin: 0;
    margin-bottom: 50px;
    font-size: 52px;
    line-height: 77px;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      height: 2.5px;
      width: 100px;
      margin-right: 20px;
      background-color: #f39a1f;
      display: inline-block;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 49px;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: flex;
      align-items: start;
      gap: 24px;
      width: calc((100% - 40px) / 3);
      font-weight: 400;
      font-size: 18px;
      line-height: 29px;
      .icon {
        min-width: 80px;
        height: 80px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        &.first {
          background-image: url(../../assets/images/climate.png);
        }
        &.second {
          background-image: url(../../assets/images/residencepermit.png);
        }
        &.third {
          background-image: url(../../assets/images/lowsafety.png);
        }
        &.fourth {
          background-image: url(../../assets/images/risinginvestment.png);
        }
        &.fifth {
          background-image: url(../../assets/images/nodisclosureofincome.png);
        }
        &.sixth {
          background-image: url(../../assets/images/welcomeeveryone.png);
        }
      }
      h3 {
        margin: 0;
        font-size: 24px;
        font-family: 'Oxygen', sans-serif;
        font-weight: 700;
      }
      p {
        margin: 8px 0 0;
        line-height: 1.2;
      }
    }
  }
}

.WhatWeSellPage {
  .searchContainer {
    top: 0;
    width: 100%;
    padding-bottom: 50px;
    padding-top: 200px;
    background-color: rgba(0, 0, 0, 0.35);
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(../../assets/images/NCyprusHeader.jpg);
    background-size: cover;
    border: 1px solid transparent;
    .container {
      flex: 1;
      max-width: 1240px;
      margin: auto;
      .sliderImgs {
        div {
          padding: 15px 10px;
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
          background: #888686;
          transform: rotate(180deg);
          a {
            text-transform: uppercase;
            display: block;
            writing-mode: vertical-rl;
            color: rgba(255, 255, 255, 0.651);
            font-family: 'Oxygen', sans-serif;
            font-size: 16px;
            padding-bottom: 5px;
            font-weight: 500;
            position: relative;
          }
        }
      }
      h1 {
        margin: 0;
        padding-left: 10px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 68px;
        line-height: 120px;
        color: white;
        span {
          color: #ef9725;
        }
      }
      .text {
        position: relative;
        max-width: 686px;
        p {
          max-width: 686px;
          margin: 0;
          font-family: 'Oxygen', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 21px;
          color: #e6e6e6;
          line-height: 1.5;
          margin-left: 15px;
        }
      }
      .dial {
        display: flex;
        margin: 25px 15px 40px;
        align-items: center;
        a {
          padding: 10px 25px;
          font-family: 'Oxygen', sans-serif;
          font-weight: bold;
          font-size: 21px;
          background-color: white;
          border-radius: 100px;
          display: inline-block;
        }
        p {
          color: white;
          margin-left: 50px;
          font-size: 21px;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.northenCyprus {
  background: #efefef;
  .container {
    max-width: 1240px;
    margin: auto;
    align-items: center;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    .northenCyprusContent {
      width: 50%;
      h2 {
        font-family: 'Oxygen', sans-serif;
        font-weight: 300;
        margin: 0;
        margin-bottom: 24px;
        font-size: 50px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          height: 2.5px;
          width: 150px;
          margin-right: 20px;
          background-color: #f39a1f;
          display: inline-block;
        }
      }
      p {
        margin: 0 0 5px 0;
        font-size: 19px;
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        display: flex;
        align-items: center;
        text-indent: 20px;
      }
    }
    .northenCyprusImages {
      width: 45%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.nicosia {
  margin-top: 60px;
  .container {
    max-width: 1240px;
    margin: auto;
    background-color: white;
    padding: 50px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    .nicosiaImg {
      width: 40%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nicosiaContent {
      width: 57%;
      h2 {
        font-family: 'Oxygen', sans-serif;
        font-weight: 300;
        margin: 0;
        margin-bottom: 24px;
        font-size: 50px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          height: 2.5px;
          width: 150px;
          margin-right: 20px;
          background-color: #f39a1f;
          display: inline-block;
        }
      }
      p {
        margin: 0 0 5px 0;
        font-size: 19px;
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        display: flex;
        align-items: center;
        text-indent: 20px;
      }
    }
  }
}

.Kyrenia {
  margin-top: 60px;
  .container {
    max-width: 1240px;
    margin: auto;
    background-color: white;
    padding: 50px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    .KyreniaImg {
      width: 41%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .KyreniaContent {
      width: 57%;
      h2 {
        font-family: 'Oxygen', sans-serif;
        font-weight: 300;
        margin: 0;
        margin-bottom: 24px;
        font-size: 50px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          height: 2.5px;
          width: 150px;
          margin-right: 20px;
          background-color: #f39a1f;
          display: inline-block;
        }
      }
      p {
        margin: 0 0 5px 0;
        font-size: 19px;
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        display: flex;
        align-items: center;
        text-indent: 20px;
      }
    }
  }
}

.Famagusta {
  margin-top: 60px;
  .container {
    max-width: 1240px;
    margin: auto;
    background-color: white;
    padding: 50px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    .FamagustaImg {
      width: 40%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .FamagustaContent {
      width: 57%;
      h2 {
        font-family: 'Oxygen', sans-serif;
        font-weight: 300;
        margin: 0;
        margin-bottom: 24px;
        font-size: 50px;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          height: 2.5px;
          width: 150px;
          margin-right: 20px;
          background-color: #f39a1f;
          display: inline-block;
        }
      }
      p {
        margin: 0 0 5px 0;
        font-size: 19px;
        font-family: 'Oxygen', sans-serif;
        font-weight: 400;
        display: flex;
        align-items: center;
        text-indent: 20px;
      }
    }
  }
}

@media (max-width: 1350px) {
  .about {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container {
    padding: 0 20px;
  }
  .Famagusta {
    margin-top: 60px;
    .container {
      margin: 0px 20px;
    }
  }
  .Kyrenia {
    margin-top: 60px;
    .container {
      margin: 0px 20px;
    }
  }
  .nicosia {
    margin-top: 60px;
    .container {
      margin: 0px 20px;
    }
  }
}

@media (max-width: 1120px) {
  .about {
    h2 {
      font-size: 40px !important;
      margin-bottom: 10px !important;
    }
  }
  .WhatWeSellPage {
    p {
      font-size: 17px !important;
    }
  }
}

@media (max-width: 1024px) {
  .about {
    padding-left: 0;
    padding-right: 0;
    padding-top: 45px;
    h2 {
      justify-content: center;
    }
    ul {
      padding: 0 15px;
      li {
        width: 48%;
      }
    }
  }
  .dial {
    a {
      font-size: 17px !important;
      height: unset !important;
      padding: 10px 18px !important;
      margin: 15px 0;
      width: 200px;
    }
  }
  .WhatWeSellPage {
    min-height: unset;
    .searchContainer {
      top: 47px;
      padding-top: 68px;
      padding-left: 20px;
      padding-right: 20px;
      .container {
        h1 {
          text-align: center;
          padding-left: 0;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 30px;
          line-height: 36px;
        }
        .text {
          max-width: 500px;
          margin: auto;
          padding-top: 15px;
          text-align: center;
          .background {
            filter: blur(39px);
          }
          p {
            font-size: 16px;
            &.visible {
              top: 15px;
              left: 0px;
            }
          }
        }
        .dial {
          margin: 30px 15px 0;
          justify-content: center;
          a {
            width: 250px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .sliderImgs {
    display: none;
  }
  .northenCyprus {
    background: #efefef;
    .container {
      max-width: 1240px;
      margin: 0 20px !important;
      align-items: center;
      padding-top: 80px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      .northenCyprusContent {
        width: 100%;
        h2 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 24px;
          font-size: 35px !important;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 150px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0 0 5px 0;
          font-size: 16px !important;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-indent: 20px;
        }
      }
      .northenCyprusImages {
        width: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .nicosia {
    margin-top: 60px;
    .container {
      max-width: 1240px;
      margin: 0 20px !important;
      background-color: white;
      padding: 40px;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .nicosiaImg {
        width: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .nicosiaContent {
        width: 100%;
        h2 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 24px;
          font-size: 40px !important;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 150px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0 0 5px 0;
          font-size: 16px !important;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-indent: 20px;
        }
      }
    }
  }
  .Kyrenia {
    margin-top: 60px;
    .container {
      max-width: 1240px;
      margin: 0 20px !important;
      background-color: white;
      padding: 40px;
      border-radius: 2px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      .KyreniaImg {
        width: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .KyreniaContent {
        width: 100%;
        h2 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 24px;
          font-size: 40px !important;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 150px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0 0 5px 0;
          font-size: 16px !important;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-indent: 20px;
        }
      }
    }
  }
  .Famagusta {
    margin-top: 60px;
    .container {
      max-width: 1240px;
      margin: 0 20px !important;
      background-color: white;
      padding: 40px;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .FamagustaImg {
        width: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .FamagustaContent {
        width: 100%;
        h2 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 24px;
          font-size: 50px;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 150px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0 0 5px 0;
          font-size: 19px;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-indent: 20px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .about {
    p {
      font-size: 14px !important;
    }
    h2 {
      padding: 0px 10px 30px;
      line-height: 50px;
      font-size: 22px;
      &::before {
        display: none;
      }
    }
    ul {
      li {
        width: 100%;
        text-align: center;
        flex-direction: column;
        align-items: center;
        h3 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .WhatWeSellPage {
    .searchContainer {
      padding-bottom: 20px;
      .text {
        padding-top: 5px !important;
        p {
          margin-top: 5px !important;
        }
      }
    }
  }

  .dial {
    flex-direction: column;
    margin: 0px 15px 0 !important;
    a {
      font-size: 14px !important;
      height: unset !important;
      padding: 10px 18px !important;
      margin: 15px 0;
      width: 200px;
    }
    p {
      font-size: 14px !important;
      margin: 0px !important;
    }
  }
  .northenCyprus {
    background: #efefef;
    .container {
      max-width: 1240px;
      margin: 0 20px !important;
      align-items: center;
      padding: 15px !important;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      .northenCyprusContent {
        width: 100%;
        h2 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 24px;
          font-size: 25px !important;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 85px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0 0 5px 0;
          font-size: 14px !important;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-indent: 20px;
        }
      }
      .northenCyprusImages {
        width: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .nicosia {
    margin-top: 50px;
    .container {
      max-width: 1240px;
      margin: 0 20px !important;
      background-color: white;
      padding: 15px !important;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .nicosiaImg {
        width: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .nicosiaContent {
        width: 100%;
        h2 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 24px;
          font-size: 25px !important;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 85px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0 0 5px 0;
          font-size: 14px !important;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-indent: 20px;
        }
      }
    }
  }
  .Kyrenia {
    margin-top: 50px;
    .container {
      max-width: 1240px;
      margin: 0 20px !important;
      background-color: white;
      padding: 15px !important;
      border-radius: 2px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      .KyreniaImg {
        width: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .KyreniaContent {
        width: 100%;
        h2 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 24px;
          font-size: 25px !important;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 85px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0 0 5px 0;
          font-size: 14px !important;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-indent: 20px;
        }
      }
    }
  }
  .Famagusta {
    margin-top: 50px;
    .container {
      max-width: 1240px;
      margin: 0 20px !important;
      background-color: white;
      padding: 15px !important;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .FamagustaImg {
        width: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .FamagustaContent {
        width: 100%;
        h2 {
          font-family: 'Oxygen', sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 24px;
          font-size: 25px !important;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            height: 2.5px;
            width: 85px;
            margin-right: 20px;
            background-color: #f39a1f;
            display: inline-block;
          }
        }
        p {
          margin: 0 0 5px 0;
          font-size: 14px !important;
          font-family: 'Oxygen', sans-serif;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-indent: 20px;
        }
      }
    }
  }
}

.northenCyprus {
  .container {
    padding-top: 50px !important;
  }
}
