.ImageGallery {
  display: flex;
  .currentImage {
    position: relative;
    display: flex;
    flex: 1;
    max-height: 651.5px;
    overflow: hidden;

    .galleryPrice{
      position: absolute;
      z-index: 99999;
      
      bottom: 0;
      background-color: white;
      padding: 10px 25px;
      font-size: 40px;
      font-weight: bold;
      color: #f39a1f;
      border-bottom-left-radius: 138px;
      border-top-right-radius: 150px;
      border-bottom-right-radius: 150px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      left: 50%;
      width: fit-content;
      transform: translateX(-204%);

    }
    .images {
      position: relative;
      display: flex;

      img {
        min-width: 100%;
        max-width: 100%;
        height: 653px;
        object-fit: cover;
      }
    }
    button {
      position: absolute;
      z-index: 1;
      top: calc(50% - 40px);
      width: 70px;
      height: 80px;
      background: #000000;
      opacity: 0.6;
      border-radius: 8px;
      border: none;
      cursor: pointer;

      &.next {
        right: 0;
      }
    }
  }

  .thumbnails {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 295px;
    height: 653px;
    margin-left: 8px;
    overflow-y: auto;

    img {
      width: 100%;
      max-height: 204px;
      min-height: 203px;
      object-fit: cover;
    }
  }
}
